.gamespin-screen{
    padding-top: 50px;
    .modal-header{
        font-size: 70px;
        padding-bottom: 30px;
        margin-bottom: 30px;
    }
    p{
        margin-bottom: 60px;
    }
    .arrow{
        img{
            @include box-size(150px, 150px);
            transform: rotate(270deg);
        }
        &.ar-right{
            img{
                transform: rotate(90deg);
            }
        }
    }
    .secountry-btn {
        width: auto;
        padding: 30px 80px;
        height: auto;
    }
}
.wheel-spiner{
    #myCanvasContainer{
        padding: 30px 0;
    }
    margin: 0 auto;
    text-align: center;
    &.disabled{
        opacity: 0.3;
    }
}
.spin-count {
    margin: 0px 25px;
    font-size: 90px;
    font-weight: $semibold;
}