.modal-header{
  font-family: $headingFont;  
  font-size: 40px;
  font-weight: 400;
  border: 0;
  padding: 0;
  justify-content: center;
}
.modal-content{
    padding: 100px;
    @include borderRadiusCard;
    ol{
        padding-left: 10px;
        li{
            font-size: 20px;
            line-height: 30px;
            padding-bottom: 10px;
            strong{
                font-weight: 700;
                font-size: 21px;
            }
        }
    }
}
.modal-body{
    padding: 40px 0 !important;
}
.modal-footer{
    border: 0;
    padding: 0;
    .secountry-btn, .primary-button{
        display: flex;
        justify-content: center;
        align-items: center;
        grid-gap: 20px;
    }
}
.modal {
    background: rgba(0, 0, 0, 0.30);
    backdrop-filter: blur(8px);
}

@media (min-width: 992px){
.modal-lg, .modal-xl {
    max-width: 840px;
}
}

.verify-modal {
    .modal-content {
        height: 800px;
        align-items: center;
        justify-content: center;
        flex-direction: inherit;
      .verifi-content{
        p{
            font-size: 24px;
            color: $blackColor;
            line-height: 28px;
            width: 74%;
            margin: 0 auto;
        }
      }
    }
}
.pt-80{
    padding-top: 80px;
}

//Spin Modal Style
.spin-modal{
    .modal-content {
        height: auto;
    }
    .scan-view{
       @include box-size(360px, 360px);
       border-radius: 60px;
       background: #D4E0F2;
       margin: 0 auto;
       overflow: hidden
    }
    .down-arrow{
        img{
            @include box-size(300px, 300px)
        }
    }
    .close-btn{
        @include box-size(70px, 70px);
        background: #D4E0F2;
        position: absolute;
        top: -20px;
        right: -20px;
        border-radius: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
.prize-modal{
    .prize-visual {
        width: 640px;
        height: 470px;
        overflow: hidden;
        border-radius: 100px;
        background: #EAECEF ;
        display: flex;
        justify-content: center;
        align-items: center;

        img{
            width: 300px;
        }
    }
    .modal-header {
        font-size: 60px;
        color: $blackColor;
        font-weight: $medium;
    }
    p{
        font-size: 32px;
        color: $blackColor;
       line-height: 38px;
    }
    .primary-button{
        width: 100%;
        margin: 80px 0;
        &.noprize-btn{
            width: 555px;
        }
    }
    .modal-body {
        padding: 0 !important;
    }
    .modal-footer{
        margin: 0 !important;
        p{
            font-size: 24px;
            text-align: center;
        }
    }
}
.no-win{
    .modal-content{
        .modal-body{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
    }
}
.spin-later{
    .primary-button{
        margin: 30px 0;
    }
    .verifi-content{
        p{
            padding-bottom: 40px;
            font-size: 38px;
            line-height: 48px;
            font-weight: 500;
        }
    }
}
.modal{
    pointer-events: none;
}
.terms-conditions{
    .modal-header{
        padding-bottom: 30px;
        font-weight: $semibold;
        line-height: 45px;
    }
    .modal-footer{
        padding-top: 30px;
    }
    .modal-body{
        height: 950px;
        overflow-y: auto;
        padding: 40px 20px !important;
    }
}